module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-PD8D90NRBD"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"USAva.Team","short_name":"USAva.Team","description":"USAva.Team specializes in a wide range of technology and design services, including e-Commerce, React, Node, Python, Go, blockchain, game development, artificial intelligence, business intelligence, computer vision, machine learning, natural language processing, as well as solutions for iOS, Android, Magento, and Shopify. Our team of experts provides cutting-edge solutions tailored to meet the needs of your business.","start_url":"/","background_color":"#4a47a3","theme_color":"#4a47a3","display":"standalone","icon":"static/logo1024.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d28521d0f444b427ae72046b1cb8d32c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://usava.team"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mailgo/gatsby-browser.js'),
      options: {"plugins":[],"mailgoConfig":{"dark":false,"actions":{"skype":false}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"439693522514258"},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
