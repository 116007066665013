import React from "react"
import "./Preloader.scss"

const Preloader = ({ progress }) => {
  return (
    <div className="preloader">
      <div className="progress-bar" style={{ width: `${progress}%` }}></div>
    </div>
  )
}

export default Preloader
