const React = require("react")
const { Provider } = require("react-redux")
const ReactDOM = require("react-dom/client")

const { ThemeProvider } = require("@mui/material/styles")
const { theme } = require("./src/theme")

const Layout = require("./src/components/Layout").default
const { store } = require("./src/redux/store")
const HeaderTop = require("./src/components/HeaderTop").default
const Footer = require("./src/components/Footer/Footer").default
const initI18n = require("./src/i18n").default
const ScopedCssBaseline = require("@mui/material/ScopedCssBaseline").default

initI18n()

exports.wrapPageElement = ({ element, props }) => {
  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline enableColorScheme>
        <div className="sava-wrapper">
          <header className="header">
            <HeaderTop showBackground={true} navigatorMenu={true} />
          </header>
          <Layout {...props}>{element}</Layout>
          <Footer />
        </div>
      </ScopedCssBaseline>
    </ThemeProvider>
  )
}

exports.wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>
}

exports.replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

exports.onRouteUpdate = ({ location }) => {
  const hash = location.hash

  if (hash) {
    const scrollToHash = () => {
      const id = hash.replace("#", "")
      const element = document.getElementById(id)

      if (element) {
        const offsetPosition =
          element.getBoundingClientRect().top + window.scrollY

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        })
      }
    }
    scrollToHash()
    setTimeout(scrollToHash, 500)
  }
}
