exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-portfolio-portfolio-page-js": () => import("./../../../src/components/Portfolio/PortfolioPage.js" /* webpackChunkName: "component---src-components-portfolio-portfolio-page-js" */),
  "component---src-dynamic-pages-requisites-index-jsx": () => import("./../../../src/dynamicPages/requisites/index.jsx" /* webpackChunkName: "component---src-dynamic-pages-requisites-index-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-personal-data-processing-policy-js": () => import("./../../../src/pages/personal-data-processing-policy.js" /* webpackChunkName: "component---src-pages-personal-data-processing-policy-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-quest-1-js": () => import("./../../../src/pages/quest-1.js" /* webpackChunkName: "component---src-pages-quest-1-js" */),
  "component---src-pages-sales-summer-js": () => import("./../../../src/pages/sales/summer.js" /* webpackChunkName: "component---src-pages-sales-summer-js" */),
  "component---src-pages-services-artificial-intelligence-ai-accelerated-engineering-js": () => import("./../../../src/pages/services/artificial-intelligence/ai-accelerated-engineering.js" /* webpackChunkName: "component---src-pages-services-artificial-intelligence-ai-accelerated-engineering-js" */),
  "component---src-pages-services-artificial-intelligence-ai-security-by-design-js": () => import("./../../../src/pages/services/artificial-intelligence/ai-security-by-design.js" /* webpackChunkName: "component---src-pages-services-artificial-intelligence-ai-security-by-design-js" */),
  "component---src-pages-services-artificial-intelligence-generative-ai-js": () => import("./../../../src/pages/services/artificial-intelligence/generative-ai.js" /* webpackChunkName: "component---src-pages-services-artificial-intelligence-generative-ai-js" */),
  "component---src-pages-services-artificial-intelligence-responsible-ai-js": () => import("./../../../src/pages/services/artificial-intelligence/responsible-ai.js" /* webpackChunkName: "component---src-pages-services-artificial-intelligence-responsible-ai-js" */),
  "component---src-pages-services-customer-experience-cx-strategy-and-design-js": () => import("./../../../src/pages/services/customer-experience/cx-strategy-and-design.js" /* webpackChunkName: "component---src-pages-services-customer-experience-cx-strategy-and-design-js" */),
  "component---src-pages-services-customer-experience-digital-transformation-js": () => import("./../../../src/pages/services/customer-experience/digital-transformation.js" /* webpackChunkName: "component---src-pages-services-customer-experience-digital-transformation-js" */),
  "component---src-pages-services-customer-experience-next-gen-marketing-js": () => import("./../../../src/pages/services/customer-experience/next-gen-marketing.js" /* webpackChunkName: "component---src-pages-services-customer-experience-next-gen-marketing-js" */),
  "component---src-pages-services-customer-experience-physical-experience-js": () => import("./../../../src/pages/services/customer-experience/physical-experience.js" /* webpackChunkName: "component---src-pages-services-customer-experience-physical-experience-js" */),
  "component---src-pages-services-customer-experience-white-space-innovation-js": () => import("./../../../src/pages/services/customer-experience/white-space-innovation.js" /* webpackChunkName: "component---src-pages-services-customer-experience-white-space-innovation-js" */),
  "component---src-pages-services-customer-experience-workforce-experience-js": () => import("./../../../src/pages/services/customer-experience/workforce-experience.js" /* webpackChunkName: "component---src-pages-services-customer-experience-workforce-experience-js" */),
  "component---src-pages-services-cybersecurity-cloud-and-data-security-js": () => import("./../../../src/pages/services/cybersecurity/cloud-and-data-security.js" /* webpackChunkName: "component---src-pages-services-cybersecurity-cloud-and-data-security-js" */),
  "component---src-pages-services-cybersecurity-cybersecurity-advisory-js": () => import("./../../../src/pages/services/cybersecurity/cybersecurity-advisory.js" /* webpackChunkName: "component---src-pages-services-cybersecurity-cybersecurity-advisory-js" */),
  "component---src-pages-services-cybersecurity-digital-risk-management-js": () => import("./../../../src/pages/services/cybersecurity/digital-risk-management.js" /* webpackChunkName: "component---src-pages-services-cybersecurity-digital-risk-management-js" */),
  "component---src-pages-services-cybersecurity-managed-detection-and-response-js": () => import("./../../../src/pages/services/cybersecurity/managed-detection-and-response.js" /* webpackChunkName: "component---src-pages-services-cybersecurity-managed-detection-and-response-js" */),
  "component---src-pages-services-cybersecurity-outside-in-validation-js": () => import("./../../../src/pages/services/cybersecurity/outside-in-validation.js" /* webpackChunkName: "component---src-pages-services-cybersecurity-outside-in-validation-js" */),
  "component---src-pages-services-cybersecurity-ransomware-protection-js": () => import("./../../../src/pages/services/cybersecurity/ransomware-protection.js" /* webpackChunkName: "component---src-pages-services-cybersecurity-ransomware-protection-js" */),
  "component---src-pages-services-cybersecurity-zero-trust-implementation-js": () => import("./../../../src/pages/services/cybersecurity/zero-trust-implementation.js" /* webpackChunkName: "component---src-pages-services-cybersecurity-zero-trust-implementation-js" */),
  "component---src-pages-services-data-and-analytics-cloud-data-migration-js": () => import("./../../../src/pages/services/data-and-analytics/cloud-data-migration.js" /* webpackChunkName: "component---src-pages-services-data-and-analytics-cloud-data-migration-js" */),
  "component---src-pages-services-engineering-agile-ways-of-working-js": () => import("./../../../src/pages/services/engineering/agile-ways-of-working.js" /* webpackChunkName: "component---src-pages-services-engineering-agile-ways-of-working-js" */),
  "component---src-pages-services-engineering-api-and-integration-js": () => import("./../../../src/pages/services/engineering/api-and-integration.js" /* webpackChunkName: "component---src-pages-services-engineering-api-and-integration-js" */),
  "component---src-pages-services-engineering-core-and-enterprise-systems-js": () => import("./../../../src/pages/services/engineering/core-and-enterprise-systems.js" /* webpackChunkName: "component---src-pages-services-engineering-core-and-enterprise-systems-js" */),
  "component---src-pages-services-engineering-devops-js": () => import("./../../../src/pages/services/engineering/devops.js" /* webpackChunkName: "component---src-pages-services-engineering-devops-js" */),
  "component---src-pages-services-engineering-iot-js": () => import("./../../../src/pages/services/engineering/iot.js" /* webpackChunkName: "component---src-pages-services-engineering-iot-js" */),
  "component---src-pages-services-engineering-mach-js": () => import("./../../../src/pages/services/engineering/mach.js" /* webpackChunkName: "component---src-pages-services-engineering-mach-js" */),
  "component---src-pages-services-engineering-metaverse-js": () => import("./../../../src/pages/services/engineering/metaverse.js" /* webpackChunkName: "component---src-pages-services-engineering-metaverse-js" */),
  "component---src-pages-services-engineering-modernization-js": () => import("./../../../src/pages/services/engineering/modernization.js" /* webpackChunkName: "component---src-pages-services-engineering-modernization-js" */),
  "component---src-pages-services-engineering-open-source-js": () => import("./../../../src/pages/services/engineering/open-source.js" /* webpackChunkName: "component---src-pages-services-engineering-open-source-js" */),
  "component---src-pages-services-engineering-physical-product-development-js": () => import("./../../../src/pages/services/engineering/physical-product-development.js" /* webpackChunkName: "component---src-pages-services-engineering-physical-product-development-js" */),
  "component---src-pages-services-engineering-platform-and-product-development-js": () => import("./../../../src/pages/services/engineering/platform-and-product-development.js" /* webpackChunkName: "component---src-pages-services-engineering-platform-and-product-development-js" */),
  "component---src-pages-services-engineering-quality-engineering-js": () => import("./../../../src/pages/services/engineering/quality-engineering.js" /* webpackChunkName: "component---src-pages-services-engineering-quality-engineering-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-magento-2-customization-service-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/magento-2-customization-service.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-magento-2-customization-service-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-magento-2-migration-service-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/magento-2-migration-service.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-magento-2-migration-service-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-magento-2-performance-optimization-services-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/magento-2-performance-optimization-services.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-magento-2-performance-optimization-services-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-magento-2-seo-services-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/magento-2-seo-services.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-magento-2-seo-services-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-magento-2-support-maintenance-services-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/magento-2-support-maintenance-services.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-magento-2-support-maintenance-services-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-magento-2-upgrade-service-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/magento-2-upgrade-service.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-magento-2-upgrade-service-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-magento-code-audit-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/magento-code-audit.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-magento-code-audit-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-magento-development-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/magento-development.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-magento-development-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-magento-ecommerce-development-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/magento-ecommerce-development.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-magento-ecommerce-development-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-magento-extension-development-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/magento-extension-development.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-magento-extension-development-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-magento-hyva-theme-development-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/magento-hyva-theme-development.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-magento-hyva-theme-development-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-magento-integration-service-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/magento-integration-service.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-magento-integration-service-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-magento-performance-audit-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/magento-performance-audit.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-magento-performance-audit-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-magento-rapid-deployment-packages-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/magento-rapid-deployment-packages.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-magento-rapid-deployment-packages-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-magento-security-audit-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/magento-security-audit.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-magento-security-audit-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-magento-security-patches-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/magento-security-patches.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-magento-security-patches-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-magento-seo-audit-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/magento-seo-audit.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-magento-seo-audit-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-magento-site-audit-services-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/magento-site-audit-services.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-magento-site-audit-services-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-magento-to-shopify-migration-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/magento-to-shopify-migration.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-magento-to-shopify-migration-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-magento-ux-audit-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/magento-ux-audit.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-magento-ux-audit-js" */),
  "component---src-pages-services-magento-and-adobe-commerce-pwa-development-services-js": () => import("./../../../src/pages/services/magento-and-adobe-commerce/pwa-development-services.js" /* webpackChunkName: "component---src-pages-services-magento-and-adobe-commerce-pwa-development-services-js" */),
  "component---src-pages-services-shopify-and-shopify-plus-shopify-app-development-js": () => import("./../../../src/pages/services/shopify-and-shopify-plus/shopify-app-development.js" /* webpackChunkName: "component---src-pages-services-shopify-and-shopify-plus-shopify-app-development-js" */),
  "component---src-pages-services-shopify-and-shopify-plus-shopify-development-services-js": () => import("./../../../src/pages/services/shopify-and-shopify-plus/shopify-development-services.js" /* webpackChunkName: "component---src-pages-services-shopify-and-shopify-plus-shopify-development-services-js" */),
  "component---src-pages-services-shopify-and-shopify-plus-shopify-integration-services-js": () => import("./../../../src/pages/services/shopify-and-shopify-plus/shopify-integration-services.js" /* webpackChunkName: "component---src-pages-services-shopify-and-shopify-plus-shopify-integration-services-js" */),
  "component---src-pages-services-shopify-and-shopify-plus-shopify-plus-development-js": () => import("./../../../src/pages/services/shopify-and-shopify-plus/shopify-plus-development.js" /* webpackChunkName: "component---src-pages-services-shopify-and-shopify-plus-shopify-plus-development-js" */),
  "component---src-pages-services-shopify-and-shopify-plus-shopify-speed-performance-optimization-services-js": () => import("./../../../src/pages/services/shopify-and-shopify-plus/shopify-speed-performance-optimization-services.js" /* webpackChunkName: "component---src-pages-services-shopify-and-shopify-plus-shopify-speed-performance-optimization-services-js" */),
  "component---src-pages-services-shopify-and-shopify-plus-shopify-store-setup-js": () => import("./../../../src/pages/services/shopify-and-shopify-plus/shopify-store-setup.js" /* webpackChunkName: "component---src-pages-services-shopify-and-shopify-plus-shopify-store-setup-js" */),
  "component---src-pages-services-shopify-and-shopify-plus-shopify-support-maintenance-services-js": () => import("./../../../src/pages/services/shopify-and-shopify-plus/shopify-support-maintenance-services.js" /* webpackChunkName: "component---src-pages-services-shopify-and-shopify-plus-shopify-support-maintenance-services-js" */),
  "component---src-pages-services-shopify-and-shopify-plus-shopify-theme-development-js": () => import("./../../../src/pages/services/shopify-and-shopify-plus/shopify-theme-development.js" /* webpackChunkName: "component---src-pages-services-shopify-and-shopify-plus-shopify-theme-development-js" */),
  "component---src-pages-services-shopify-and-shopify-plus-shopify-website-design-js": () => import("./../../../src/pages/services/shopify-and-shopify-plus/shopify-website-design.js" /* webpackChunkName: "component---src-pages-services-shopify-and-shopify-plus-shopify-website-design-js" */),
  "component---src-pages-services-strategy-generative-ai-advisory-js": () => import("./../../../src/pages/services/strategy/generative-ai-advisory.js" /* webpackChunkName: "component---src-pages-services-strategy-generative-ai-advisory-js" */),
  "component---src-pages-services-strategy-mergers-and-acquisitions-js": () => import("./../../../src/pages/services/strategy/mergers-and-acquisitions.js" /* webpackChunkName: "component---src-pages-services-strategy-mergers-and-acquisitions-js" */),
  "component---src-pages-services-strategy-optimizing-for-growth-js": () => import("./../../../src/pages/services/strategy/optimizing-for-growth.js" /* webpackChunkName: "component---src-pages-services-strategy-optimizing-for-growth-js" */),
  "component---src-pages-services-strategy-project-to-product-js": () => import("./../../../src/pages/services/strategy/project-to-product.js" /* webpackChunkName: "component---src-pages-services-strategy-project-to-product-js" */),
  "component---src-pages-services-strategy-transformative-research-and-insights-js": () => import("./../../../src/pages/services/strategy/transformative-research-and-insights.js" /* webpackChunkName: "component---src-pages-services-strategy-transformative-research-and-insights-js" */),
  "component---src-pages-services-website-development-ecommerce-website-development-js": () => import("./../../../src/pages/services/website-development/ecommerce-website-development.js" /* webpackChunkName: "component---src-pages-services-website-development-ecommerce-website-development-js" */),
  "component---src-pages-services-website-development-javascript-development-js": () => import("./../../../src/pages/services/website-development/javascript-development.js" /* webpackChunkName: "component---src-pages-services-website-development-javascript-development-js" */),
  "component---src-pages-services-website-development-laravel-development-js": () => import("./../../../src/pages/services/website-development/laravel-development.js" /* webpackChunkName: "component---src-pages-services-website-development-laravel-development-js" */),
  "component---src-pages-services-website-development-nodejs-development-js": () => import("./../../../src/pages/services/website-development/nodejs-development.js" /* webpackChunkName: "component---src-pages-services-website-development-nodejs-development-js" */),
  "component---src-pages-services-website-development-php-development-js": () => import("./../../../src/pages/services/website-development/php-development.js" /* webpackChunkName: "component---src-pages-services-website-development-php-development-js" */),
  "component---src-pages-services-website-development-python-development-js": () => import("./../../../src/pages/services/website-development/python-development.js" /* webpackChunkName: "component---src-pages-services-website-development-python-development-js" */),
  "component---src-pages-services-website-development-qa-consulting-js": () => import("./../../../src/pages/services/website-development/qa-consulting.js" /* webpackChunkName: "component---src-pages-services-website-development-qa-consulting-js" */),
  "component---src-pages-services-website-development-react-js-development-js": () => import("./../../../src/pages/services/website-development/react-js-development.js" /* webpackChunkName: "component---src-pages-services-website-development-react-js-development-js" */),
  "component---src-pages-services-website-development-salesforce-development-js": () => import("./../../../src/pages/services/website-development/salesforce-development.js" /* webpackChunkName: "component---src-pages-services-website-development-salesforce-development-js" */),
  "component---src-pages-services-website-development-software-development-js": () => import("./../../../src/pages/services/website-development/software-development.js" /* webpackChunkName: "component---src-pages-services-website-development-software-development-js" */),
  "component---src-pages-services-website-development-test-automation-services-js": () => import("./../../../src/pages/services/website-development/test-automation-services.js" /* webpackChunkName: "component---src-pages-services-website-development-test-automation-services-js" */),
  "component---src-pages-services-website-development-website-design-js": () => import("./../../../src/pages/services/website-development/website-design.js" /* webpackChunkName: "component---src-pages-services-website-development-website-design-js" */),
  "component---src-pages-services-website-development-website-development-consulting-js": () => import("./../../../src/pages/services/website-development/website-development-consulting.js" /* webpackChunkName: "component---src-pages-services-website-development-website-development-consulting-js" */),
  "component---src-pages-services-website-development-website-development-js": () => import("./../../../src/pages/services/website-development/website-development.js" /* webpackChunkName: "component---src-pages-services-website-development-website-development-js" */),
  "component---src-pages-services-website-development-website-maintenance-js": () => import("./../../../src/pages/services/website-development/website-maintenance.js" /* webpackChunkName: "component---src-pages-services-website-development-website-maintenance-js" */),
  "component---src-pages-services-website-development-wordpress-development-js": () => import("./../../../src/pages/services/website-development/wordpress-development.js" /* webpackChunkName: "component---src-pages-services-website-development-wordpress-development-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

